import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
} from "@mui/material";
import * as Styles from "../../common/Styles";
import {
  useGetcommisionjuspayData,
  useGetMembershipjuspayData,
  useGetPinelabsData,
} from "../../Hooks/ExternalApiData/useGetExternalApiData";
import { useNavigate } from "react-router-dom";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useSelector, useDispatch } from "react-redux";
import { AllCompanysDetails } from "../../redux/action/Company&User/AllCompanys";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import CompanyTypeComp from "../AllRecords/CompanyTypeComp";
import Searchbar from "../../common/Searchbar";

function PineLabsData() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [AllCompanys, setAllCompanys] = useState();
  const [searchData, setSearchData] = useState("");
  const [activePanel, setActivePanel] = useState("membership");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: getPineLabsData,
    isError: getPineLabsDataError,
    isLoading: getPineLabsDataLoading,
    refetch: getPineLabsDataRefetch,
  } = useGetMembershipjuspayData(currentPage, searchData);

  const {
    data: getuseGetcommisionjuspayData,
    isError: getuseGetcommisionjuspayDataError,
    isLoading: getuseGetcommisionjuspayDataLoading,
    refetch: getuseGetcommisionjuspayDataRefetch,
  } = useGetcommisionjuspayData(currentPage, searchData);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (activePanel === "transactions") {
      if (
        getuseGetcommisionjuspayData &&
        getuseGetcommisionjuspayData?.totalPages !== undefined
      ) {
        setInitialPaginationCount(getuseGetcommisionjuspayData?.totalPages);
      }
    } else {
      if (getPineLabsData && getPineLabsData?.totalPages !== undefined) {
        setInitialPaginationCount(getPineLabsData?.totalPages);
      }
    }
  }, [getPineLabsData, getuseGetcommisionjuspayData, activePanel]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/allexternalapi");
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Box className="page-heading">
            <h5>All JusPay(s) Data</h5>
            <Typography>View all payment details from members.</Typography>
          </Box>
        </Box>
        <Divider />{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "97%",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total Company :
              {activePanel == "transactions"
                ? getuseGetcommisionjuspayData?.totalCount || 0
                : getPineLabsData?.totalCount || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              minWidth: "20%",
              width: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              height: "auto",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setActivePanel("transactions")}
              sx={{
                backgroundColor:
                  activePanel == "transactions" ? "#445FD2" : "#FFFFFF",
                color: activePanel == "transactions" ? "#ffffff" : "#445FD2",
                borderRadius: "10px",
                height: "100%",
                width: "auto",
                textAlign: "center",
                cursor: "pointer",
                textTransform: "none",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#445FD2",
                },
              }}
            >
              Transactions
            </Button>
            <Button
              variant="contained"
              onClick={() => setActivePanel("membership")}
              sx={{
                backgroundColor:
                  activePanel == "membership" ? "#445FD2" : "#FFFFFF",
                color: activePanel == "membership" ? "#ffffff" : "#445FD2",
                borderRadius: "10px",
                width: "auto",
                height: "100%",
                textAlign: "center",
                textTransform: "none",
                cursor: "pointer",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#445FD2",
                },
              }}
            >
              Membership
            </Button>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 2,
            mt: 0,
          }}
        >
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {(
              activePanel == "membership"
                ? getPineLabsDataLoading
                : getuseGetcommisionjuspayDataLoading
            ) ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ml: "500px",
                  mt: 20,
                }}
              >
                <PageLoader />
              </Box>
            ) : activePanel == "membership" ? (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "80vw",
                  minWidth: "80vw",
                  height: "auto",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer sx={TableContainerStyle}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>No</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              MembershipId
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              JusPay Order Id
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Company Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Category
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Email</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Membership Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Membership Type
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Sign up Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Active Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Expiry Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Gateway
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Payment Method
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Payment date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Charge Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Status</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <Divider /> */}

                        {getPineLabsData?.data?.map((x, i) => {
                          const continuousIdx = (currentPage - 1) * 10 + i + 1;
                          // console.log("membership", x);
                          return (
                            <TableRow key={i}>
                              <TypographyComponent>
                                {continuousIdx}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.companymembershipData?.MembershipId
                                  ? x?.companymembershipData?.MembershipId
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"200px"}>
                                {x?.data?.order_id ? x?.data?.order_id : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"250px"}>
                                {x?.companyData?.companyName || "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.companyData?.companyType ? (
                                  <CompanyTypeComp
                                    companyTypeId={x?.companyData?.companyType}
                                  />
                                ) : (
                                  "--"
                                )}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.companyData?.email || "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"180px"}>
                                {x?.companymembershipData?.MembershipAmount
                                  ? x?.companymembershipData?.MembershipAmount
                                  : "--"}
                              </TypographyComponent>

                              <TypographyComponent width={"200px"}>
                                {x?.companymembershipData?.MembershipPlan
                                  ?.PlanName || "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"100px"}>
                                {x?.companyData?.createdAt
                                  ? formatDate(x?.companyData?.createdAt)
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"100px"}>
                                {x?.companymembershipData?.MembershipStartDate
                                  ? formatDate(
                                      x?.companymembershipData
                                        ?.MembershipStartDate
                                    )
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"100px"}>
                                {x?.companymembershipData?.MembershipExpiryDate
                                  ? formatDate(
                                      x?.companymembershipData
                                        ?.MembershipExpiryDate
                                    )
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.data?.txn_detail?.gateway || "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.data?.payment_method || "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {formatDate(x?.data?.date_created) || "--"}
                              </TypographyComponent>
                              {/* charge amount */}
                              <TypographyComponent>{"--"}</TypographyComponent>
                              <TypographyComponent>
                                {x?.data?.status}
                              </TypographyComponent>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "80vw",
                  minWidth: "80vw",
                  height: "auto",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer sx={TableContainerStyle}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>No</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              CommisionId
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              JusPay Order Id
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Company Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Category
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Email</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Status</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Gateway
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Payment Method
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Payment date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Charge Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <Divider /> */}

                        {getuseGetcommisionjuspayData?.data?.map((x, i) => {
                          // console.log(x, "commission");
                          const continuousIdx = (currentPage - 1) * 10 + i + 1;
                          return (
                            <TableRow key={i}>
                              <TypographyComponent>
                                {continuousIdx}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.commissionInvoiceData?.CommitionId
                                  ? x?.commissionInvoiceData?.CommitionId
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"200px"}>
                                {x?.commissionJuspayResponse?.order_id
                                  ? x?.commissionJuspayResponse?.order_id
                                  : "--"}
                              </TypographyComponent>
                              <TypographyComponent width={"250px"}>
                                {x?.PurchaseOrderData?.SellerDetails
                                  ?.SellerCompanyName || "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.companyData?.companyType ? (
                                  <CompanyTypeComp
                                    companyTypeId={x?.companyData?.companyType}
                                  />
                                ) : (
                                  "--"
                                )}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.companyData?.email || "--"}
                              </TypographyComponent>

                              <TypographyComponent>
                                {x?.commissionJuspayResponse?.status}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.commissionJuspayResponse?.txn_detail
                                  ?.gateway || "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {x?.commissionJuspayResponse?.payment_method ||
                                  "--"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {formatDate(
                                  x?.commissionJuspayResponse?.date_created
                                ) || "--"}
                              </TypographyComponent>
                              {/* charge amount */}
                              <TypographyComponent>{"--"}</TypographyComponent>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default PineLabsData;

const TypographyComponent = ({ children, ...props }) => {
  return (
    <TableCell>
      <Typography sx={{ ...TextStyleCommon, width: props.width }}>
        {children}
      </Typography>
    </TableCell>
  );
};

const headText = {
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  alignItems: "left",
};
const buddge = {
  display: "inline-flex",
  height: 40,
  padding: "16px 20px 16px 15px",
  justifyContent: "center",
  alignItems: "center",
  gap: 0,
  borderRadius: 10,
  border: "1px solid #E6E9EE",
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
  width: "auto",
};
